//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapState} from 'vuex'
import CookieMessage from '../common/cookieMessage'
import ShoppingCartSidebar from '../common/shopping-cart-sidebar'

export default {
  name: 'Page',
  components: {CookieMessage, ShoppingCartSidebar},
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  head() {
    return {
      title: this.content.title.fieldValue.value,
      description: this.content.description.fieldValue.value,
      keywords: this.content.keywords.fieldValue.value,
      meta: [
        {
          name: 'og:title',
          content: this.content.title.fieldValue.value,
        },
        {
          name: 'og:description',
          content: this.content.description.fieldValue.value,
        },
      ],
      script: [
        {
          type: 'text/javascript',
          src: this.$config.squareurl,
        },
      ],
    }
  },
  computed: {
    ...mapState('shoppingCart', ['cartSideOpen']),
  },
  methods: {
    ...mapActions('shoppingCart', ['setCartSideOpen']),
    closeCloseSide() {
      this.setCartSideOpen(false)
    },
  },
}
